import { Container } from '@Calix-Commerce/design-system/layout';
import { ClickAwayListener } from '@mui/material';
import { getI18NText } from 'I18N';
import ExportSvg from 'assets/export.svg';
import { CheckoutFooter } from 'components/Footer';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { storageCurrentQuoteSelector } from 'state/selectors';
import {
  mapCheckoutDataToQuote,
  readCheckoutSavedData,
  removeCheckoutDataFromLocalStorage,
} from 'utils/helpers/checkout';
import { getQuoteGroupMapping } from 'utils/helpers/quote';
import {
  useAppNavigation,
  useFormatPrice,
  useQuoteActionController,
  useQuoteController,
} from 'utils/hooks';
import {
  ExportOptionsContainer,
  ReviewContainer,
  ReviewFormHeader,
  SaveToFileButton,
  AcknowledgmentMessage,
} from './styledComponents';
import { AdditionalOrderConfirmation } from './subcomponents/AdditionalOrderConfirmation';
import { BillingDetails } from './subcomponents/BillingDetails';
import { ShippingDetails } from './subcomponents/ShippingDetails';
import { ProgressButton } from 'components/Footer/styledComponents';
import { withQuoteLoad } from 'hocs';
import { withCheckoutPermissions } from 'hocs/withCheckoutPermissions';
import { CheckoutStepper } from 'components/stepper';
import { withCheckoutBillingConfigured } from 'hocs/withCheckoutBillingConfigured';
import { CURRENT_CHECKOUT_STEP } from 'utils/constants/checkout';
import { CheckoutQuoteDetails } from 'pages/QuoteDetails/subcomponents/CheckoutQuoteDetails';
import { currentQuoteValidationState, notificationState } from 'state/atoms';
import { withCheckoutAccessible } from 'hocs/withCheckoutAccessible';
import { FileType } from 'types/FileType';

const CheckoutReviewPage = () => {
  const { transactionId } = useParams();
  const { exportQuote, submitQuote } = useQuoteActionController();
  const setNotification = useSetRecoilState(notificationState);
  const checkoutData = readCheckoutSavedData(transactionId);
  const formatPrice = useFormatPrice();
  const currentQuote = useRecoilValue(storageCurrentQuoteSelector);
  const validationState = useRecoilValue(currentQuoteValidationState);
  const setCurrentQuote = useSetRecoilState(storageCurrentQuoteSelector);
  const quoteGroups = getQuoteGroupMapping(currentQuote, formatPrice, true);
  const { getQuoteTotalWithExtendedWarrantyPriceLabel } = useQuoteController();
  const priceDisplay = getQuoteTotalWithExtendedWarrantyPriceLabel();
  const { redirectToCheckoutBilling, redirectToCheckoutConfirmation } = useAppNavigation();
  const [showExportOptions, setShowExportOptions] = useState(false);
  const [recipients, setRecipients] = useState([]);

  const submitOrder = async () => {
    const {
      shippingAddress,
      shippingInfo,
      shippingContact,
      billingAddress,
      lesingAgent,
      poNumber,
    } = checkoutData;
    const shipmentSchedules =
      shippingInfo?.shipmentSchedule.map((shippingSchedule) => ({
        shipDate: shippingSchedule.shipDate,
        lines: shippingSchedule.lines.map((line) => ({
          partNumber: line.partNumber,
          quantity: line.quantity,
        })),
      })) || [];

    const finalQuoteData = {
      ...currentQuote,
      ...mapCheckoutDataToQuote({
        shippingAddress,
        shipmentSchedules,
        shippingContact,
        billingAddress,
        recipients,
        lesingAgent,
        poNumber,
        shippingInfo,
      }),
    };

    setCurrentQuote(finalQuoteData);

    return submitQuote({
      transactionId: transactionId || '',
      quoteData: finalQuoteData,
    });
  };

  const uploadPoFile = async () => {
    const fileUploadURL = `${import.meta.env.VITE_CALIX_PORTAL_URL}/bin/calix/servlets/icsProxy?dom=${import.meta.env.VITE_OIC_DOMAIN}&ep=${import.meta.env.VITE_OIC_FILE_UPLOAD_ENDPOINT}`;

    const fileUploadResponse = await fetch(fileUploadURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        fileData: checkoutData.poFile.fileData,
        fileName: checkoutData.poFile.fileName,
        fileSize: checkoutData.poFile.fileSize,
        transactionId,
      }),
    });

    if (fileUploadResponse.status === 200) {
      return Promise.resolve(await fileUploadResponse.json());
    }

    setNotification({
      text: getI18NText('CHECKOUT_FILE_UPLOAD_ERROR'),
      show: true,
      type: 'error',
      timeout: null,
    });

    return Promise.reject('Failure in file upload...');
  };

  const backNavigationConfig = {
    navigate: async () => redirectToCheckoutBilling(),
  };

  const nextNavigationValidation = async () => {
    try {
      if (quoteGroups.some((quoteGroup) => quoteGroup.items.some((item) => !item.available))) {
        setNotification({
          text: getI18NText('CHECKOUT_SUBMIT_QUOTE_ERROR'),
          show: true,
          type: 'error',
          timeout: null,
        });

        return Promise.reject();
      }

      if (checkoutData.poFile) {
        await uploadPoFile();
      }

      await submitOrder();

      removeCheckoutDataFromLocalStorage();

      Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const nextNavigationConfig = {
    navigate: async () => redirectToCheckoutConfirmation(),
    validate: nextNavigationValidation,
  };

  const oneTimeDiscountPrice = currentQuote.calculatedOneTimeDiscount
    ? formatPrice(currentQuote.calculatedOneTimeDiscount)
    : null;

  return (
    <Container>
      <CheckoutStepper currentStep={CURRENT_CHECKOUT_STEP.REVIEW}></CheckoutStepper>
      <ReviewFormHeader>{getI18NText('REVIEW_YOUR_ORDER')}</ReviewFormHeader>
      <ReviewContainer>
        <ShippingDetails />
        <BillingDetails />
      </ReviewContainer>
      <CheckoutQuoteDetails
        quoteGroups={quoteGroups}
        readonly={true}
        quoteTotalPrice={priceDisplay}
        oneTimeDiscountPrice={oneTimeDiscountPrice}
      />
      <ReviewFormHeader>{getI18NText('ADDITIONAL_ORDER_CONFIRMATION_RECIPIENTS')}</ReviewFormHeader>
      <AdditionalOrderConfirmation recipients={recipients} onRecipientsChange={setRecipients} />
      <AcknowledgmentMessage>
        By submitting this order you hereby certify that the above information is accurate and
        correct, and agree to the purchase of all items specified herein. Purchases of Calix
        Products are subject to the terms and conditions of the purchase agreement executed between
        Customer and Calix.
      </AcknowledgmentMessage>
      <CheckoutFooter
        backNavigationConfig={backNavigationConfig}
        nextNavigationConfig={nextNavigationConfig}
        nextButtonText={getI18NText('SUBMIT_ORDER')}
        prefixElements={
          <>
            {validationState.isWritable ? (
              <SaveToFileButton
                size="small"
                variant="text"
                startIcon={<ExportSvg />}
                onClick={() => {
                  setShowExportOptions(true);
                }}
              >
                {getI18NText('SAVE_TO_FILE')}
              </SaveToFileButton>
            ) : null}
            {showExportOptions && (
              <ClickAwayListener
                onClickAway={() => {
                  setShowExportOptions(false);
                }}
              >
                <ExportOptionsContainer>
                  <div className="export-header">{getI18NText('SELECT_FILE_FORMAT')}</div>
                  <div className="export-options">
                    <ProgressButton
                      onClick={() => {
                        exportQuote({ transactionId, fileType: FileType.PDF });
                        setShowExportOptions(false);
                      }}
                    >
                      {getI18NText('PDF')}
                    </ProgressButton>
                    <ProgressButton
                      onClick={() => {
                        exportQuote({ transactionId, fileType: FileType.XLS });
                        setShowExportOptions(false);
                      }}
                    >
                      {getI18NText('XLS')}
                    </ProgressButton>
                  </div>
                </ExportOptionsContainer>
              </ClickAwayListener>
            )}
          </>
        }
        nextButtonProps={{
          colorVariant: 'secondary',
        }}
      />
    </Container>
  );
};

export const CheckoutReview = withCheckoutPermissions(
  withQuoteLoad(withCheckoutAccessible(withCheckoutBillingConfigured(CheckoutReviewPage)))
);
