import { Flex } from '@Calix-Commerce/design-system/layout';
import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { CheckoutTitle } from 'components/Checkout';

export const ReviewFormHeader = styled(CheckoutTitle)`
  margin-bottom: 0px;
`;

export const ReviewFormContainer = styled.table`
  width: 100%;

  tr {
    td {
      font-size: 1rem;
      color: #333;
      line-height: 150%;
      text-align: left;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      width: 50%;

      .review-ship-link {
        color: #195d8d;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      .review-ship-label,
      .review-ship-value {
        margin-bottom: 0px;
        margin-top: 0px;
      }

      .review-ship-value,
      .review-ship-link {
        font-weight: normal;
      }

      .shipment-schedule-details {
        margin: 0 0 10px 0;

        .shipment-group-header {
          border-bottom: 1px solid #ccc;
          width: 100%;
          height: 22px;

          .ship-date {
            width: 84%;
            height: 100%;
            float: left;
          }

          .quantity {
            width: 16%;
            height: 100%;
            font-weight: normal;
            float: right;
            text-align: right;
          }
        }

        .shipment-schedule-list {
          width: 100%;
          height: 22px;

          .part-name {
            width: 84%;
            float: left;
            font-weight: normal;
          }

          .quantity {
            width: 16%;
            font-weight: normal;
            float: right;
            text-align: right;
          }
        }
      }
    }

    td:first-child {
      font-weight: 700;
    }
  }
`;

export const ReviewContainer = styled(Flex)`
  margin-bottom: 18px;
`;

export const SaveToFileButton = styled(Button)`
  height: 38.1px;
  margin-top: 1rem;
  color: #0b38db;
  text-transform: none;

  .MuiButton-startIcon {
    margin-right: 0;

    svg {
      path {
        fill: #0b38db;
        transition: fill 0.4s;
      }
    }
  }

  &:hover {
    color: #ff7600;
    background-color: transparent;

    .MuiButton-startIcon {
      svg {
        path {
          fill: #ff7600;
        }
      }
    }
  }
`;

export const ExportOptionsContainer = styled.div`
  width: 240px;
  height: 103px;
  border: 1px solid #a0a0a0;
  background-color: #ffffff;
  position: absolute;
  z-index: 10;
  right: 40px;
  bottom: 0px;
  animation: exportMove 0.7s;

  .export-header {
    background-color: #f0f0f0;
    padding: 10px;
    height: 41px;
    width: 100%;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 1rem;
    color: #333333;
    text-align: left;
  }

  .export-options {
    width: 100%;
    height: 62px;
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;

    .MuiButtonBase-root {
      margin-top: 0;
    }
  }

  @keyframes exportMove {
    from {
      bottom: -103px;
      opacity: 0;
    }
    to {
      bottom: 0px;
      opacity: 1;
    }
  }
`;

export const AcknowledgmentMessage = styled.p`
  padding: 15px;
  font-size: 1rem;
  font-weight: normal;
  line-height: 150%;
  color: #8a6d3b;
  background-color: #fcf8e3;
  border: 1px solid #faebcc;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
