import AddIcon from '@mui/icons-material/Add';
import { useCallback, useState } from 'react';
import { Quote } from 'types';
import { useApolloClient } from '@apollo/client';
import { GET_QUOTE_LIST } from 'connectors/queries/quote';
import { QUOTES_PER_REQUEST_LIMIT } from 'utils/constants';
import { useRecoilValue } from 'recoil';
import { selectedAccountState } from 'state/atoms';
import unique from 'lodash/uniq';
import { Flex } from '@Calix-Commerce/design-system';
import {
  Tag,
  TagForm,
  TagInput,
  TagListItem,
  TagSubmitButton,
  TagsListWrapper,
  TagsWrapper,
} from './styledComponents';

const Tags = ({ tags, removeTag, addTag, readonly }) => {
  const [tagInput, setTagInput] = useState('');
  const [tagList, setTagList] = useState<string[]>([]);
  const { accountId } = useRecoilValue(selectedAccountState);
  const apolloClient = useApolloClient();

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      if (!tagInput || !tagInput.trim()) {
        return;
      }

      addTag(tagInput);
      setTagInput('');
    },
    [tagInput, addTag, setTagInput]
  );

  const loadQuoteListFromCache = useCallback(
    (quoteList: Quote[] = []): string[] => {
      const {
        getAccount: {
          listQuotes: { hasMore, quotes = [] },
        },
      } = apolloClient.readQuery({
        query: GET_QUOTE_LIST,
        variables: { accountId, limit: QUOTES_PER_REQUEST_LIMIT, offset: 0 },
      });

      const allQuoteList = [...quoteList, ...quotes].filter((quote) => quote);

      if (hasMore) {
        return loadQuoteListFromCache(allQuoteList);
      }

      return unique(allQuoteList.map((quote) => quote.tags).flat(2));
    },
    [accountId, apolloClient]
  );

  const filterTagList = (term: string) => {
    if (!term) {
      setTagList([]);
      return;
    }

    try {
      setTagList(
        loadQuoteListFromCache().filter((tag: string) =>
          tag.toLowerCase().includes(term.toLowerCase())
        )
      );
    } catch (e) {
      setTagList([]);
    }
  };

  return (
    <TagsWrapper>
      <label>Tags:</label>
      <TagsListWrapper>
        {tags.map((name) => (
          <Tag onClick={() => removeTag(name)} key={name}>
            <span style={{ paddingRight: '8px' }}>{name}</span>
            <span>X</span>
          </Tag>
        ))}
      </TagsListWrapper>
      {!readonly ? (
        <TagForm onSubmit={handleSubmit}>
          <Flex style={{ position: 'relative' }}>
            <TagInput
              placeholder="Add a tag"
              type="text"
              value={tagInput}
              onChange={(e) => {
                filterTagList(e.target.value);
                setTagInput(e.target.value);
              }}
            ></TagInput>
            <div
              id="searchPartList"
              style={{
                position: 'absolute',
                zIndex: '1',
                background: '#fff',
                margin: '32px 0',
                width: '240px',
              }}
            >
              <ul style={{ margin: '0', padding: '0', maxHeight: '250px', overflowY: 'scroll' }}>
                {tagList.map((tag) => {
                  return (
                    <TagListItem
                      onClick={() => {
                        setTagInput(tag);
                        setTagList([]);
                      }}
                      key={tag}
                    >
                      {tag}
                    </TagListItem>
                  );
                })}
              </ul>
            </div>
          </Flex>
          <TagSubmitButton type="submit">
            <AddIcon />
          </TagSubmitButton>
        </TagForm>
      ) : null}
    </TagsWrapper>
  );
};

export { Tags };
