import { ApolloCache, DefaultContext, MutationUpdaterFunction } from '@apollo/client';
import cloneDeep from 'lodash/cloneDeep';
import { GET_QUOTE_LIST } from 'connectors/queries/quote';
import { QUOTES_PER_REQUEST_LIMIT } from 'utils/constants';

type QuoteMutationType = MutationUpdaterFunction<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  DefaultContext,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ApolloCache<any>
>;
const removeQuoteFromCache = ({ cache, accountId, transactionId }) => {
  const queryVariables = {
    accountId,
    limit: QUOTES_PER_REQUEST_LIMIT,
    offset: 0,
  };
  for (let pagination = 0; ; pagination++) {
    queryVariables.offset = QUOTES_PER_REQUEST_LIMIT * pagination;
    const data =
      (cache.readQuery({
        query: GET_QUOTE_LIST,
        variables: queryVariables,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }) as any) || {};

    // Add our comment from the mutation to the end.
    let quotes = data?.getAccount?.listQuotes?.quotes || [];

    if (!quotes.length) {
      break;
    }

    const beforeLength = quotes.length;
    quotes = quotes.filter((item) => !item || item.transactionId !== transactionId);

    if (beforeLength > quotes.length) {
      const copyOfData = cloneDeep(data);
      copyOfData.getAccount.listQuotes.quotes = quotes.filter((quote) => quote);

      // Write our data back to the cache.
      cache.writeQuery({
        query: GET_QUOTE_LIST,
        variables: queryVariables,
        data: copyOfData,
      });
      break;
    }

    if (!data.getAccount.listQuotes.hasMore) {
      break;
    }
  }
};

const addItemOnCache = ({ cache, accountId, quoteData }) => {
  const queryVariables = {
    accountId,
    limit: QUOTES_PER_REQUEST_LIMIT,
    offset: 0,
  };

  const data = (cache.readQuery({
    query: GET_QUOTE_LIST,
    variables: queryVariables,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) as any) || {
    getAccount: {
      accountId,
      listQuotes: {
        count: 0,
        hasMore: false,
        limit: queryVariables.limit,
        quotes: [],
      },
    },
  };

  // Add our comment from the mutation to the end.
  let quotes = data?.getAccount?.listQuotes?.quotes || [];

  quotes = [quoteData, ...quotes].filter((quote) => quote);

  const copyOfData = cloneDeep(data);
  copyOfData.getAccount.listQuotes.quotes = quotes;

  // Write our data back to the cache.
  cache.writeQuery({
    query: GET_QUOTE_LIST,
    variables: queryVariables,
    data: copyOfData,
  });
};

export const alterQuoteMutationUpdate: QuoteMutationType = (cache, { data: { alterQuote } }) => {
  const { transactionId, accountId } = alterQuote;
  removeQuoteFromCache({
    cache,
    accountId,
    transactionId,
  });
  addItemOnCache({ cache, accountId, quoteData: alterQuote });
};

export const createQuoteMutationUpdate: QuoteMutationType = (cache, { data: { createQuote } }) => {
  const { accountId } = createQuote;
  addItemOnCache({ cache, accountId, quoteData: createQuote });
};

export const cloneQuoteMutationUpdate: QuoteMutationType = (cache, { data: { cloneQuote } }) => {
  const { accountId } = cloneQuote;
  addItemOnCache({ cache, accountId, quoteData: cloneQuote });
};

export const deleteQuoteMutationUpdate: QuoteMutationType = (cache, _, { variables }) => {
  const { accountId, transactionId } = variables;
  removeQuoteFromCache({
    cache,
    accountId,
    transactionId,
  });
};
